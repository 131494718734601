<template>
  <div class="Left">
    <div class="whole">
      <div class="farmland">农机基本情况</div>
      <div class="nongtianBox">
        <div class="part">
          <img src="../../../assets/overviewImg/righticon1.png" alt="" />
          <div>
            <p class="illustrate">农机总量</p>
            <span style="display: flex">
              <p>{{ MainByTopData.carCount }}</p>
              <p style="color: #01c696">台</p>
            </span>
          </div>
        </div>
        <div class="part">
          <img src="../../../assets/overviewImg/righticon2.png" alt="" />
          <div>
            <p class="illustrate">农机手</p>
            <span style="display: flex">
              <p>{{ MainByTopData.operatorCount }}</p>
              <p style="color: #01c696">名</p>
            </span>
          </div>
        </div>
        <div class="part" @click="dunkClick">
          <img src="../../../assets/overviewImg/righticon3.png" alt="" />
          <div>
            <p class="illustrate">作业亩次</p>
            <span style="display: flex">
              <p>{{ MainByTopData.completedArea }}</p>
              <p style="color: #01c696">亩</p>
            </span>
          </div>
        </div>
        <div class="part">
          <img src="../../../assets/overviewImg/righticon4.png" alt="" />
          <div>
            <p class="illustrate">作业亩数</p>
            <span style="display: flex">
              <p>{{ MainByTopData.completedCount }}</p>
              <p style="color: #01c696">次</p>
            </span>
          </div>
        </div>
      </div>
      <div class="farmland">农机类型情况</div>
      <div id="myChart"></div>
      <div id="myChartTwo"></div>
    </div>
    <tanchuang
      :visible.sync="visibleInfo"
      :TanleContent.sync="TanleContent"
      @getFinOrder="getFinOrder"
      :total.sync="total"
      :queryObj="queryObj"
      :options="options"
      :selectvalue="checkValue"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @selectChange="selectChange"
    ></tanchuang>
  </div>
</template>
  
  <script>
// 弹窗
import tanchuang from "../components/tanchuang.vue";
import { getFinishOrderByTop, getUserByParentId } from "@api/zongApi/index.js";
export default {
  name: "left",
  components: {
    tanchuang,
  },
  data() {
    return {
      allxData: [],
      firstAgrData: [],
      secondbeidouCount: [],
      machineryNumber: 0, // 第一个图表总数
      beidouNumber: 0, //第二个图表总数
      visibleInfo: false,
      TanleContent: null, // 表格数据
      options: [], // 农事服务中心选择器数据
      checkValue: "", // 农事服务中心选择器绑定数据
      total: null,
      queryObj: {
        pageSize: 10,
        pageNum: 1,
      },
      jobType: "", //类型
    };
  },
  props: ["MainByTopData", "agrData", "xData", "beidouCount"],
  created() {
    this.getByParentId();
  },
  mounted() {},
  watch: {
    xData(val) {
      this.allxData = val;
    },
    agrData(val) {
      this.firstAgrData = val;
      for (var i = this.firstAgrData.length - 1; i >= 0; i--) {
        this.machineryNumber += parseInt(this.firstAgrData[i]);
      }
      this.getEcharts();
    },
    beidouCount(val) {
      this.secondbeidouCount = val;
      for (var i = this.secondbeidouCount.length - 1; i >= 0; i--) {
        this.beidouNumber += parseInt(this.secondbeidouCount[i]);
      }
      this.getEchartsTwo();
    },
  },
  methods: {
    getEcharts() {
      const myChart = this.$echarts.init(document.getElementById("myChart"));
      myChart.setOption(
        {
          xAxis: {
            type: "category",
            data: this.allxData,
            axisLabel: {
              color: "#fff",
            },
          },
          tooltip: {
            trigger: "axis",
          },
          yAxis: {
            type: "value",
            axisLabel: {
              color: "#fff",
            },
          },
          legend: {
            data: ["总数" + ":" + this.machineryNumber],
            x: "right",
            textStyle: {
              color: "#fff",
            },
          },
          grid: {
            left: "3%",
            right: "3%",
            bottom: "3%", //3%
            top: "15%",
            containLabel: true,
          },
          series: [
            {
              name: "总数" + ":" + this.machineryNumber,
              data: this.firstAgrData,
              type: "bar",
              barWidth: 15,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  { offset: 1, color: "#20baee" },
                  { offset: 0, color: "#0d4b6d" },
                ]),
              },
            },
          ],
        },
        true
      );
    },
    getEchartsTwo() {
      const myChart = this.$echarts.init(document.getElementById("myChartTwo"));
      myChart.setOption(
        {
          xAxis: {
            type: "category",
            data: this.allxData,
            axisLabel: {
              color: "#fff",
            },
          },
          tooltip: {
            trigger: "axis",
          },
          yAxis: {
            type: "value",
            axisLabel: {
              color: "#fff",
            },
          },
          legend: {
            data: ["已加装北斗数" + ":" + this.beidouNumber],
            x: "right",
            textStyle: {
              color: "#fff",
            },
          },
          grid: {
            left: "3%",
            right: "3%",
            bottom: "3%", //3%
            top: "15%",
            containLabel: true,
          },
          series: [
            {
              name: "已加装北斗数" + ":" + this.beidouNumber,
              data: this.secondbeidouCount,
              type: "bar",
              barWidth: 15,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  { offset: 1, color: "#26e1a8" },
                  { offset: 0, color: "#0b404c" },
                ]),
              },
            },
          ],
        },
        true
      );
    },
    dunkClick() {
      this.visibleInfo = true;
    },
    handleSizeChange(item) {
      this.queryObj.pageSize = item;
      this.gettabkeData();
    },
    handleCurrentChange(item, name) {
      this.queryObj.pageNum = item;
      this.gettabkeData();
    },
    ///获取所有农事服务中心
    getByParentId() {
      getUserByParentId({
        id: sessionStorage.getItem("ID"),
      })
        .then((res) => {
          this.options = res.data.map((item) => {
            return {
              value: item.tenantId,
              label: item.tenantName,
            };
          });
          this.checkValue = this.options[2].value;
          this.gettabkeData();
        })
        .catch((err) => {});
    },
    // 表格数据
    gettabkeData() {
      getFinishOrderByTop({
        tenantId: this.checkValue,
        jobType: this.jobType,
        pageNum: this.queryObj.pageNum,
        pageSize: this.queryObj.pageSize,
      })
        .then((res) => {
          if (res.data != null) {
            this.TanleContent = res.data.records || [];
          
            this.total = res.data.total;
          }else{
            this.TanleContent=[]
            this.total=0
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getFinOrder(status, jobType) {
      this.jobType = jobType;
      this.gettabkeData();
    },
    selectChange(val) {
      this.checkValue = val;
      this.gettabkeData();
    },
  },
};
</script>
  
  <style scoped lang="scss">
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
* {
  color: #fff;
  // box-sizing: border-box;
}
.whole {
  background-image: url(../../../assets/overviewImg/biankuang.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: companyW(480vw);
  height: companyH(909vh);
  padding: companyH(25vh) companyW(22vw);
  // position: relative;
}

.farmland {
  background-image: url(../../../assets/overviewImg/qxxx.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #fff;
  text-align: center;
  margin: 0 auto;
  height: companyH(40vh);
  width: companyW(280vw);
  font-size: companyW(24vw);
  line-height: companyH(40vh);
  font-weight: 700;
  margin-bottom: companyH(20vh);
}
.nongtianBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  // margin-top: companyH(16vh);
  margin-bottom: companyH(20vh);
  .part {
    display: flex;
    align-items: center;
    width: 38%;
    margin-bottom: companyH(16vh);
    img {
      height: companyH(80vh);
      width: companyW(48vw);
      margin-right: companyW(8vw);
    }
    .illustrate {
      color: #01c696;
      font-size: companyW(16vw);
      margin-bottom: companyH(10vh);
    }
  }
}
#myChart {
  // width: companyW(430vw);
  height: companyH(280vh);
  // margin: auto;
}
#myChartTwo {
  // width: companyW(430vw);
  height: companyH(280vh);
  // margin: auto;
}
</style>
  