<template>
  <div class="dyr-service">
    <Title
      class="dyr-service-title"
      njTitle="dingbu"
      typeOf="overviewImg"
      title="台州市现代农事服务中心"
    ></Title>
    <div class="main">
      <Left class="Left" :basicData="basicData" :topData="topData"></Left>
      <div id="dituEcharts"></div>
      <Right
        class="Right"
        :MainByTopData="MainByTopData"
        :agrData="agrData"
        :xData="xData"
        :beidouCount="beidouCount"
      ></Right>
    </div>
    <bottomNav class="middle-bottomNav" :index="1"/>
    
  </div>
</template>

<script>
import Title from './components/title.vue';
import Left from './components/zonglanLeft.vue';
import Right from './components/zonglanRight.vue';
import bottomNav from './components/bottomNav.vue';
import echarts from 'echarts';
import {
  getUserByParentId,
  getBasic,
  getTop,
  selectAgriMainByTop,
  selectAgriTypeByTop,
} from '@api/zongApi/index.js';
export default {
  components: {
    Title,
    Left,
    Right,
    bottomNav,
  },
  created() {
    this.getUserByParentId();
  },
  mounted() {},
  data() {
    return {
      basicData: '',
      topData: '',
      MainByTopData: '',
      agrData: [],
      xData: [],
      beidouCount: [],

      scatterData: [], //标点数据
      quData: [], // 区级
      shiData: [], //市级
      shengData: [], //省级
      allcityData: [], // 所有中心服务数据
    };
  },
  methods: {
    getUserByParentId() {
      getUserByParentId({
        id: sessionStorage.getItem('ID'),
      })
        .then(res => {
          this.allcityData = res.data;
          res.data.forEach(item => {
            if (item.centerLevel == 1) {
              this.quData.push({
                name: item.tenantName,
                value: [ item.longitude, item.latitude ],
                tId: item.tenantId,
              });
            } else if (item.centerLevel == 2) {
              this.shiData.push({
                name: item.tenantName,
                value: [ item.longitude, item.latitude ],
                tId: item.tenantId,
              });
            } else {
              this.shengData.push({
                name: item.tenantName,
                value: [ item.longitude, item.latitude ],
                tId: item.tenantId,
              });
            }
          });
          this.scatterData = [
            {
              name: '省级农事服务中心',
              num: this.shengData.length,
              color: [ '#f16c08', '#7f3710' ],
              img: '//nsfwzx1.oss-cn-hangzhou.aliyuncs.com/202401307865黄.png',
              data: this.shengData,
            },
            {
              name: '区级农事服务中心',
              num: this.quData.length,
              color: [ '#62dc34', '#23976e' ],
              img: '//nsfwzx1.oss-cn-hangzhou.aliyuncs.com/202401308611绿.png',
              data: this.quData,
            },
            {
              name: '农事服务中心',
              num: this.shiData.length,
              color: [ '#07d0bf', '#0f93a3' ],
              img: '//nsfwzx1.oss-cn-hangzhou.aliyuncs.com/202401306029蓝.png',
              data: this.shiData,
            },
          ];

          this.getDitu(); // 构建地图

          this.getBasicData();
          this.getTopData();
          this.selectAgriMainByTopData();
          this.selectAgriTypeByTopData();
        })
        .catch(err => {
          console.log(err);
        });
    },
    //农田基本情况
    getBasicData() {
      getBasic({})
        .then(res => {
          this.basicData = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    //主体top10
    getTopData() {
      getTop({})
        .then(res => {
          this.topData = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 农机基本情况
    selectAgriMainByTopData() {
      selectAgriMainByTop({})
        .then(res => {
          this.MainByTopData = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    selectAgriTypeByTopData() {
      selectAgriTypeByTop({})
        .then(res => {
          this.agrData = res.data.map(item => {
            return item.COUNT;
          });
          this.xData = res.data.map(item => {
            return item.car_type;
          });
          this.beidouCount = res.data.map(item => {
            return item.beidouCount;
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
    getDitu() {
      const that = this;

      const myChart = this.$echarts.init(
        document.getElementById('dituEcharts')
      );

      var option = null;
      var uploadedDataURL =
        'https://nsfwzx1.oss-cn-hangzhou.aliyuncs.com/202401290384111.json';
      // 散点图数据

      let serData = [];
      // 散点图配置
      this.scatterData.forEach(item => {
        serData.push({
          name: `${ item.name }  ${ item.num }`,
          type: 'effectScatter',
          coordinateSystem: 'geo',
          effectType: 'ripple',
          showEffectOn: 'render',
          // 散点样式
          rippleEffect: {
            period: 1,
            scale: 2,
            brushType: 'fill',
          },
          // 散点大小 自定义散点图片
          symbol: `image://${ item.img }`,
          symbolSize: [ 35, 35 ],
          hoverAnimation: true,
          // 标志的样式
          itemStyle: {
            normal: {
              color: 'red',
              shadowBlur: 10,
              shadowColor: '#333',
            },
          },
          label: {
            normal: {
              formatter: '{b}',
              position: 'top',
              show: true,
              color: '#fff',
              fontSize: 14,
              distance: -2,
              backgroundColor: {
                type: 'linear',
                x: 1,
                y: 1,
                r: 0.5,
                colorStops: [
                  {
                    offset: 0,
                    color: item.color[0],
                  },
                  {
                    offset: 1,
                    color: item.color[1],
                  },
                ],
              },
              padding: 5,
            },
          },
          zlevel: 99,
          data: item.data,
        });
      });

      $.get(uploadedDataURL, function (json) {
        echarts.registerMap('js', json);
        option = {
          // backgroundColor: "#020933",
          legend: {
            show: true,
            left: '5%',
            bottom: '5%',
            orient: 'vertical',
            itemHeight: 30, //修改icon图形大小
            itemWidth: 30, //修改icon图形大小
            textStyle: {
              color: '#fff',
              fontSize: 24,
            },
          },
          geo: [
            {
              map: 'js',
              roam: true, //是否允许缩放
              zoom: 1.1, //默认显示级别
              scaleLimit: {
                min: 0.5,
                max: 3,
              }, //缩放级别
              itemStyle: {
                normal: {
                  areaColor: '#3894ec',
                  borderColor: '#3fdaff',
                  borderWidth: 2,
                  shadowColor: 'rgba(63, 218, 255, 0.5)',
                  shadowBlur: 30,
                },
                emphasis: {
                  areaColor: '#2B91B7',
                },
              },
              label: {
                show: true,
                color: '#ffffff',
                emphasis: {
                  color: '#020933',
                },
              },
              tooltip: {
                show: false,
              },
            },
          ],
          // 散点图配置
          series: serData,
        };
        myChart.setOption(option);
        myChart.on('click', params => {
          if (params.data.tId != null) {

            const targetObj = that.allcityData.find(
              obj => obj.tenantName == params.data.name
            );
            sessionStorage.setItem('ID', targetObj.id);
            sessionStorage.setItem('tenantId', targetObj.tenantId);
            localStorage.setItem('address', targetObj.tenantName);

            const mapPoint = {
              longitude: targetObj.longitude,
              latitude: targetObj.latitude,
              name: targetObj.tenantName,
            };
            localStorage.setItem('key', JSON.stringify(mapPoint));
            that.$router.push('/');
          }
        });
      });
    },
  },
};
</script>

  <style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
.dyr-service {
  position: relative;
  width: companyW(1920vw);
  height: companyH(1080vh);
  box-sizing: border-box;
  overflow: hidden;
  background-image: url(../../assets/overviewImg/bgc.jpg);
  background-color: rgba(8, 27, 25);
  background-size: cover;
  background-repeat: no-repeat;

  .dyr-service-title {
    padding-top: companyH(8vh);
    width: companyW(1820vw);
    height: companyH(70vh);
    margin: 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .dyr-service-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .dyr-service-left {
      width: companyW(482vw);
      height: companyH(978vh);
      margin-left: companyW(20vw);
      .dyr-service-service {
        margin-top: companyH(21vh);
      }
    }
    .dyr-service-right {
      margin-right: companyW(20vw);
      height: companyH(978vh);
      .right_fin {
        margin-top: companyH(21vh);
      }
    }
  }
  .middle-bottomNav {
    position: absolute;
    bottom: 0;
    left: companyW(632vw);
    background-image: url(../../assets/overviewImg/dibubgc.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}                     

.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 companyW(31vw);
  .Left {
    // margin-left: companyW(31vw);
  }
  .Right {
    // margin-right: companyW(31vw);
  }
}
#dituEcharts {
  width: companyW(800vw);
  height: companyH(800vh);
  // margin-top: companyH(100vh);
}
</style>

