<template>
    <div class="dry-power">


      <div class="dry-power-content">
        <div class="dbbj"></div>
        <div class="dbbj second"></div>
        <div class="dbbj third"></div>
        <div class="item" @click="openModel(item)" v-for="(item, index) in data" :key="index + 'z'">
          <div class="item-title">{{ item.count }}</div>
          <div class="data">
            <div class="value">{{ item.job_type }}作业</div>
            <div class="unit" v-if="item.job_type == '耕整' || item.job_type == '机插' || item.job_type == '植保' || item.job_type == '机收'">(亩)
            </div>
            <div class="unit" v-else-if="item.job_type == '加工' || item.job_type == '烘干'">(吨)</div>
            <div class="unit" v-else-if="item.job_type == '育秧'">(盘)</div>
          </div>
        </div>
      </div>
    </div>
  </template>
       
  <script>
  export default {
    components: {
    },
    props: {
      data: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
      
      }
    },
    methods: {
      openModel(e) {
        this.$listeners.getTabal({
          pageNum: 1,
          pageSize: 5,
          status: 'yearNum',
          type: e.job_type
        })
        // console.log(e,'eeee')
      }
    }
  };
  </script>
      
  <style lang="scss" scoped>
  @function companyW($num) {
    @return ($num * 100)/1920;
  }
  
  @function companyH($num) {
    @return ($num * 100)/1080;
  }
  
  .dry-power {
    width: 100%;
    height: 100%;
    background: rgba(0, 20, 36, .4);
    border: companyW(1vw) solid;
    border-image: linear-gradient(0deg, rgba(130,245,255,.4), rgba(130,245,255,.1)) 10 10;
  
    // .title {
    //   width: calc(100% - companyW(50vw));
    //   padding-left: companyW(50vw);
    //   height: companyH(40vh);
    //   line-height: companyH(40vh);
    //   color: #FFFFFF;
    //   font-weight: bold;
    //   background-image: url('../../../assets/home-page/titleImg.png');
    //   background-size: 100% 200%;
    //   background-repeat: no-repeat;
    // }
  
    .dry-power-content {
      position: relative;
      padding: companyH(10vh) 0 companyH(10vh) companyW(35vw);
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
  
      .dbbj {
        position: absolute;
        top: companyH(50vh);
        height: companyH(53vh);
        width: calc(100% - companyW(35vw));
        left: companyW(13vw);
        background-image: url('../../../assets/overviewImg/dbbj.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
  
      .second {
        top: companyH(130vh);
      }
  
      .third {
        top: companyH(213vh);
      }
  
      .item {
        width: 27%;
        background-image: url('../../../assets/overviewImg/zygkbg.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: companyH(65vh);
        padding-top: companyH(15vh);
        margin: 0 companyW(20vw) 0 0;
        z-index: 2;
  
        .item-title {
          font-size: companyW(17vw);
          color: #65EAFF;
          font-weight: bold;
          text-align: center;
          height: companyH(30vh);
          line-height: companyH(30vh);
        }
  
        .data {
          color: #FFFFFF;
          display: flex;
          justify-content: center;
          text-align: center;
  
          .value {
            font-size: companyW(14vw);
            padding-left: companyW(5vw);
          }
  
          .unit {
            font-size: companyW(14vw);
          }
        }
      }
  
      .item:nth-of-type(3n+0) {
        margin-right: 0;
      }
    }
  }</style>
      