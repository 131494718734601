<template>
  <div class="Left">
    <div class="whole">
      <div class="farmland">农田基本情况</div>
      <div class="nongtianBox">
        <div class="part">
          <img src="../../../assets/overviewImg/icon1.png" alt="" />
          <div>
            <p class="illustrate">总农田面积</p>
            <span style="display: flex">
              <p>{{ basicData.totalFieldArea }}</p>
              <p style="color: #01c696">亩</p>
            </span>
          </div>
        </div>
        <div class="part">
          <img src="../../../assets/overviewImg/icon2.png" alt="" />
          <div>
            <p class="illustrate">自有农田面积</p>
            <span style="display: flex">
              <p>{{ basicData.totalOwnFieldArea }}</p>
              <p style="color: #01c696">亩</p>
            </span>
          </div>
        </div>
        <div class="part">
          <img src="../../../assets/overviewImg/icon3.png" alt="" />
          <div>
            <p class="illustrate">粮食功能产区</p>
            <span style="display: flex">
              <p>{{ basicData.totalFoodFieldArea }}</p>
              <p style="color: #01c696">亩</p>
            </span>
          </div>
        </div>
        <div class="part">
          <img src="../../../assets/overviewImg/icon4.png" alt="" />
          <div>
            <p class="illustrate">农业主体</p>
            <span style="display: flex">
              <p>{{ basicData.totalAgricultureFieldCount }}</p>
              <p style="color: #01c696">个</p>
            </span>
          </div>
        </div>
      </div>
      <div class="farmland">作业基本情况</div>

      <echarts :option="option" id="my-echarts"></echarts>

      <div class="leftBottom">
        <leftBottom :data="listData"></leftBottom>
      </div>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import leftBottom from "../components/leftBottom.vue";
import { getJobOrderTypeByTop, getMainByTop } from "@api/zongApi/index.js";
import echarts from "@/components/echarts/index.vue";
export default {
  name: "left",
  components: {
    vueSeamlessScroll,
    leftBottom,
    echarts,
  },
  data() {
    return {
      echartData: [],
      listData: [],
      option: null,
    };
  },
  created() {
    this.getmainDra();
    this.getechartData();
  },
  props: ["basicData", "topData"],
  mounted() {},

  methods: {
    getmainDra() {
      getMainByTop()
        .then((res) => {
          this.listData = res.data;
        })
        .catch((err) => {});
    },
    getechartData() {
      getJobOrderTypeByTop({
        year: "2023",
      })
        .then((res) => {
          this.echartData = [
            {
              value: res.data[0].reap,
              name: "机收作业",
            },
            {
              value: res.data[0].transplant,
              name: "机插作业",
            },
            {
              value: res.data[0].protection,
              name: "植保作业",
            },
            {
              value: res.data[0].tillage,
              name: "耕整作业",
            },
          ];

          this.option = {
            title: {
              text:  res.data[0].amount,
              subtext: "总作业量",
              x: "center",
              y: "center",
              textAlign: "center",
              top: "34%",
              left: "47%",
              textStyle: {
                color: "#fff",
                fontSize: 14,
                fontWeight: 400,
              },
              subtextStyle: {
                color: "#fff",
                fontSize: 16,
                fontWeight: 400,
              },
            },
            tooltip: {
              trigger: "item",
            },
            legend: {
              show: true,
              // top: "1%",
              left: "center",
              textStyle: {
                color: "#fff",
              },
            },
            tooltip: {
              formatter: "{b}:{c}",
            },
            grid: {},
            series: [
              {
                itemStyle: {
                  borderWidth: 0,
                  normal: {
                    color: function (colors) {
                      var colorList = [
                        "#73c0de",
                        "#91cc75",
                        "#fac858",
                        "#ee6666",
                      ];
                      return colorList[colors.dataIndex];
                    },
                  },
                },
                type: "pie",
                radius: ["50%", "80%"],
                avoidLabelOverlap: false,
                label: {
                  show: false,
                  position: "center",
                },
                emphasis: {
                  label: {
                    show: false,
                    fontSize: 40,
                    fontWeight: "bold",
                  },
                },
                labelLine: {
                  show: false,
                },
                data: this.echartData,
              },
            ],
          };
        })
        .catch((err) => {});
    },
  },
};
</script>

<style scoped lang="scss">
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
* {
  color: #fff;
  // box-sizing: border-box;
}
.whole {
  background-image: url(../../../assets/overviewImg/biankuang.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: companyW(480vw);
  height: companyH(909vh);
  padding: companyH(25vh) companyW(22vw);
  position: relative;
}

.farmland {
  background-image: url(../../../assets/overviewImg/qxxx.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #fff;
  text-align: center;
  margin: 0 auto;
  height: companyH(40vh);
  width: companyW(280vw);
  font-size: companyW(24vw);
  line-height: companyH(40vh);
  font-weight: 700;
  margin-bottom: companyH(20vh);
}
.nongtianBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: companyH(16vh);
  margin-bottom: companyH(20vh);
  .part {
    display: flex;
    align-items: center;
    width: 38%;
    margin-bottom: companyH(16vh);
    img {
      height: companyH(80vh);
      width: companyW(48vw);
      margin-right: companyW(8vw);
    }
    .illustrate {
      color: #01c696;
      font-size: companyW(16vw);
      margin-bottom: companyH(10vh);
    }
  }
}
.individuality {
  display: flex;
  justify-content: space-between;
  padding: 0 companyW(15vw);
  align-items: center;

  height: companyH(50vh);
  border-radius: companyH(15vh);
  background-color: rgb(10, 46, 40, 0.5);
  margin-bottom: companyH(8vh);
  p:nth-child(2) {
    color: #00dae2;
  }
}
.seamless-warp {
  height: companyH(560vh);
  overflow: hidden;
}
#my-echarts {
  width: companyW(430vw);
  height: companyH(280vh);
}
.leftBottom {
  width: 100%;
  // height: 32%;
  // margin-bottom: companyH(20vh);
}
</style>
