import request from '@/utils/request.js';

//根据父级id查询所有子级数据
export function getUserByParentId(params) {
  return request({
    url: '/user/getUserByParentId',
    method: 'get',
    params,
  });
}

//市级获取农田基本情况
export function getBasic(params) {
  return request({
    url: '/field/getBasic',
    method: 'get',
    params,
  });
}

//获取主体top10
export function getTop(params) {
  return request({
    url: '/field/getTop',
    method: 'get',
    params,
  });
}
//驾驶舱农机总览首页获取数据(管理端)
export function selectAgriMainByTop(params) {
  return request({
    url: '/agricultual/selectAgriMainByTop',
    method: 'get',
    params,
  });
}

//驾驶舱农机总览首页获取农机类型数据(管理端)
export function selectAgriTypeByTop(params) {
  return request({
    url: '/agricultual/selectAgriTypeByTop',
    method: 'get',
    params,
  });
}

export function getMainByTop(params) {
  return request({
    url: '/dryingOrProcessOrder/getMainByTop',
    method: 'get',
    params,
  });
}

export function getJobOrderTypeByTop(params) {
  return request({
    url: '/jobOrder/getJobOrderTypeByTop',
    method: 'get',
    params,
  });
}
export function getFinishOrderByTop(params) {
  return request({
    url: '/jobOrder/getFinishOrderByTop',
    method: 'get',
    params,
  });
}
